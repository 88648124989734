/* eslint-disable */

import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import stencilUtils from "@bigcommerce/stencil-utils";

export default (function (context) {

    if ($(".section-content").length > 0) {
        setTimeout(() => {
            getshopbycategory();
        }, 500);
    }

    function getshopbycategory() {
        document.querySelectorAll('.section-content')[0].classList.remove('isloading');
    }

    window.stencilUtils.hooks.on('product-options-change-remote', (data) => { 
        const jsonResponse = JSON.stringify(data);
        const responseObject = JSON.parse(jsonResponse);
        console.log(responseObject);
        const inStockValue = responseObject.response.data.instock;
        const purchasable = responseObject.response.data.purchasing_message;
        if (inStockValue == true && purchasable !== 'The selected product combination is currently unavailable.') {
           document.querySelector(".in-stock").style.display = "flex";
                document.querySelector(".sold-out").style.display = "none";
             
        } 
        else if(purchasable == 'The selected product combination is currently unavailable.')
            {
                document.querySelector(".sold-out").style.display = "block";
                document.querySelector(".in-stock").style.display = "none";
            }
        else{
            document.querySelector(".sold-out").style.display = "block";
            document.querySelector(".in-stock").style.display = "none";
        }
     });

    $(document).ready(function () {

        $(".media-section iframe").attr('title', 'About');
        /*active sidebar category link as current open category page using breadcrumb*/
        function activesidebar() {
            var total = $(".breadcrumbs .breadcrumb").length;
            for (var i = 1; i < total; i++) {
                var tar_text = $.trim($(".breadcrumbs .breadcrumb").eq(i).find("a").attr("href"));
                $(".action-icon").each(function () {
                    var t_text = $.trim($(this).siblings(".navPages-action").attr("href"));
                    if (t_text == tar_text) {

                        $(this).siblings(".navPages-action").addClass("is-open");
                        $(this).addClass("is-open");
                        $(this).siblings('.second-level-category').addClass('is-open').slideDown();
                        $(this).siblings("ul").toggle();
                    } else {

                    }
                });
            }
            var lastmenu = $.trim($(".breadcrumb.is-active a").attr("href"));

            $(".first-level-category li").each(function () {
                var lastmenum = $.trim($(this).find("a").attr("href"));
                if (lastmenum == lastmenu) {
                    $(this).children(".navPages-action").addClass("is-open");
                }
            });

            $(".second-level-category li").each(function () {
                var lastmenum = $.trim($(this).find("a").attr("href"));
                if (lastmenum == lastmenu) {
                    $(this).parents('.second-level-category').addClass('is-open');
                    $(this).children(".navPages-action").addClass("is-open");
                    $(this).children(".action-icon").addClass("is-open");
                    $(this).children('.third-level-category').addClass('is-open').slideDown();
                }
            });

            $(".third-level-category li").each(function () {
                var lastmenum = $.trim($(this).find("a").attr("href"));
                if (lastmenum == lastmenu) {
                    $(this).parents('.third-level-category').addClass('is-open').slideDown();
                    $(this).children(".navPages-action").addClass("is-open");
                }
            });
        }
        // Compare functionality
        function comparefunctionality() {

            var product_array = JSON.parse(localStorage.getItem("products-compare"));
            if (product_array != null) {
                var compare_url = '/compare/';
                for (var i = 0; i < product_array.length; i++) {
                    $('[data-product-id="' + product_array[i] + '"').find('.button.compare').addClass('active');
                    compare_url += product_array[i] + '/';
                }
                $('a.navUser-action.navUser-item--compare').attr('data-href', compare_url);
                if (product_array.length == 0) {
                    $('.navUser-item--compare .countPill.countPill--positive').html('');
                    $('.navUser-item--compare .countPill.countPill--positive').hide();
                } else {
                    $('.navUser-item--compare .countPill.countPill--positive').html(product_array.length);
                    $('.navUser-item--compare .countPill.countPill--positive').show();
                }

            }

            // Compare remove and add on click events
            $("a.navUser-action.navUser-item--compare").on('click', function (event) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

                var product_array = JSON.parse(localStorage.getItem("products-compare"));
                if (product_array && product_array.length > 0) {
                    if (product_array.length > 1)
                        window.location.href = $('a.navUser-action.navUser-item--compare').attr('data-href');
                    else if (product_array.length == 1)
                        $('#add-more-product-popup').fadeIn().delay(2500).fadeOut();
                }
                else
                    $('#no-product-popup').fadeIn().delay(2500).fadeOut();
                //else if(product_array.length==1)
            });

            $("a.compareTable-removeProduct").on('click', function (event) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

                var product_id = $(this).attr('data-product-id');
                var product_array = JSON.parse(localStorage.getItem("products-compare"));
                var new_array = [];
                var compare_url = '/compare/';
                for (var i = 0; i < product_array.length; i++) {
                    if (product_array[i] != product_id) {
                        new_array.push(product_array[i]);
                        compare_url += product_array[i] + '/';
                    }
                }
                $('a.navUser-action.navUser-item--compare').attr('data-href', compare_url);
                localStorage.setItem("products-compare", JSON.stringify(new_array));
                $('#product-remove-popup').fadeIn().delay(2500).fadeOut();
                //$('.countPill.countPill--positive').html(new_array.length);
                window.location.href = $('a.navUser-action.navUser-item--compare').attr('data-href');
            });

            $(".button.card-figcaption-button.compare").on('click', function (event) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                var dialogue_msg = "";
                var product_id = $(this).find('[data-compare-id]').val();
                var product_array = JSON.parse(localStorage.getItem("products-compare"));
                var max = false;
                if (product_array == null) {
                    product_array = [product_id];
                    dialogue_msg = "add";
                    $(this).addClass('active');
                }
                else {
                    var found = 0;
                    for (var i = 0; i < product_array.length; i++) {
                        if (product_array[i] == product_id) {
                            found = 1;
                            break;
                        }
                    }
                    if (found == 0) {
                        if (product_array.length < 4) {
                            product_array.push(product_id);
                            dialogue_msg = "add";
                            $(this).addClass('active');
                        } else
                            max = true;
                    }
                }
                if (max == false) {
                    if (found == 1) {
                        var tmp_array = [];
                        for (var i = 0; i < product_array.length; i++) {
                            if (product_array[i] != product_id) {
                                tmp_array.push(product_array[i]);
                            }
                        }
                        product_array = tmp_array;
                        dialogue_msg = "remove";
                        $(this).removeClass('active');
                    }
                    var compare_url = '/compare/';
                    for (var i = 0; i < product_array.length; i++) {
                        compare_url += product_array[i] + '/';
                    }
                    $('a.navUser-action.navUser-item--compare').attr('data-href', compare_url);

                    if (product_array.length == 0) {
                        $('.navUser-item--compare .countPill.countPill--positive').html('');
                        $('.navUser-item--compare .countPill.countPill--positive').hide();
                    } else {
                        $('.navUser-item--compare .countPill.countPill--positive').show();
                        $('.navUser-item--compare .countPill.countPill--positive').html(product_array.length);

                    }
                    localStorage.setItem("products-compare", JSON.stringify(product_array));
                    $('#product-' + dialogue_msg + '-popup').fadeIn().delay(2500).fadeOut();
                } else
                    $('#max-product-popup').fadeIn().delay(2500).fadeOut();

            });

            $('.compare-popup .popup-section .close-btn').on('click', function () {
                $(this).parents('.compare-popup').hide();
            });
        }
        $(window).load(function () {
            //gridListViewOption();
            if ($('.product-view-button').length > 0) {
                var view = localStorage.getItem('view');
                if (view == '1') {
                    $('.product-view-button .grid-icon').trigger('click')
                }
                else if (view == '2') {
                    $('.product-view-button .list-icon').trigger('click');
                }
            }

        });
        function headermenulink() {
            if ($(window).width() > 1025) {
                $(".navPages-list a.navPages-action").click(function (e) {
                    var cart_url = $(this).attr('href');
                    e.preventDefault();
                    window.location = cart_url;
                });
            }
        }
        window.addEventListener('resize', function () {
            headermenulink();
        });

        $(document).on('change', '#sort', function () {
            setTimeout(() => {
                comparefunctionality();
            }, 2500);

        });
        /*gridListViewOption*/

        $(document).on('click', '.product-view-button .list-icon', function () {
            localStorage.setItem('view', 2);
            $('body').addClass('listview');
            if ($('body').hasClass('gridview')) {
                $('body').removeClass('gridview');
            } else {
                $('body').addClass('listview');
            }
            comparefunctionality();
        });
        $(document).on('click', '.product-view-button .grid-icon', function () {
            localStorage.setItem('view', 1);
            $('body').addClass('gridview');
            if ($('body').hasClass('listview')) {
                $('body').removeClass('listview');
            } else {
                $('body').addClass('gridview');
            }
            comparefunctionality();
        });

        /*gridListViewOption End*/

        $('.owl-carousel.fashion-popular').owlCarousel({
            loop: true,
            margin: 10,
            dots: false,
            nav: true,
            items: 1
        });
        $('.owl-carousel.jewellery-popular').owlCarousel({
            loop: true,
            margin: 10,
            dots: true,
            nav: false,
            items: 1
        });
        $('.owl-carousel.furniture-popular').owlCarousel({
            loop: true,
            margin: 10,
            dots: true,
            nav: false,
            items: 1
        });
        headermenulink();
        // footer accordion
        $(function () {
            $('.footer .footer-info-links .footer-info-heading').on('click', function () {
                if (window.innerWidth < 1024) {
                    $('.footer .footer-info-heading').not(this).removeClass('is-open');
                    $('.footer .footer-info-heading').not(this).next(".footer-info-list").slideUp(350);
                    $(this).toggleClass('is-open');
                    $(this).next().slideToggle(350);
                }
            });

        });

        if (window.innerWidth > 1023) {
            $(".footer-info-links .footer-info-list").css({ "display": "block" });
            $(".footer-info-links.brands-section .footer-info-list").css({ "display": "flex" });
        } else {
            $(".footer-info-links .footer-info-list").css({ "display": "none" });
        }
        $(window).resize(function () {
            if (window.innerWidth > 1023) {
                $(".footer-info-links .footer-info-list").css({ "display": "block" });
                $('.footer .footer-info-heading').removeClass('is-open');
                $(".footer-info-links.brands-section .footer-info-list").css({ "display": "flex" });
            } else {
                $(".footer-info-links .footer-info-list").css({ "display": "none" });
                $('.footer .footer-info-heading').removeClass('is-open');
            }
        });

        // category decsription expand collapse

        $('.expand').click(function () {
            $(this).toggleClass("collapse");
            $('.description .text').toggleClass("is-open");
        });

        activesidebar();
        comparefunctionality();
        $(document).ajaxComplete(function () {
            comparefunctionality();
        });

        if ($('.instagram').length > 0) {
            var insta_token = context.instaToken;
            var settings = {
                "url": "https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,username,timestamp&access_token=" + insta_token + "&limit=10",
                "method": "GET",
                "resolution": "standard_resolution",
                "timeout": 0,
                "async": false,
            };
            $.ajax(settings).done(function (response) {
                var obj = JSON.parse(JSON.stringify(response.data));
                $.each(obj, function (index, item) {
                    if (item.media_type != "VIDEO") {

                        var _html = '<div class="content"><a class="custom_insta_link" href="' + item.permalink + '" target="_blank"><img src="' + item.media_url + '" alt=""><div class="hover-link"><div><span class="link-icon"><svg><use xlink:href="#icon-instagram"></use></svg></span><button class="button">Shop the Look</button></div></div>' + '</a></div>';
                    }
                    $('#instafeed').append(_html);

                });

                var divs = $("#instafeed");
                for (var i = 1; i < divs.length; i += 5) {
                    divs.slice(i, i + 4).wrapAll("<div class='new-div'></div>");
                }
                $(".instafeed-fashion").slick({
                    arrows: true,
                    dots: false,
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: false,
                    autoplaySpeed: 8000,
                    responsive: [
                        {
                            breakpoint: 1599,
                            settings: {
                                slidesToShow: 5,
                            }
                        },
                        {
                            breakpoint: 1367,
                            settings: {
                                slidesToShow: 4,
                            }
                        },
                        {
                            breakpoint: 1025,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 769,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 569,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
                $(".instafeed-jewellery").slick({
                    arrows: true,
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '402px',
                    autoplay: false,
                    autoplaySpeed: 8000,
                    responsive: [
                        {
                            breakpoint: 1680,
                            settings: {
                                slidesToShow: 3,
                                centerPadding: '100px',
                            }
                        },
                        {
                            breakpoint: 1535,
                            settings: {
                                slidesToShow: 3,
                                centerPadding: '40px',
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                                centerPadding: '40px',
                            }
                        },
                        {
                            breakpoint: 569,
                            settings: {
                                slidesToShow: 1,
                                centerPadding: '40px',
                            }
                        }
                    ]
                });
                $(".instafeed-furniture").slick({
                    arrows: false,
                    dots: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: false,
                    autoplay: false,
                    autoplaySpeed: 8000,
                    responsive: [
                        {
                            breakpoint: 1537,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 1365,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 769,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 569,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });

                $('#instafeed').show();
                $('.instagram-content .loading').hide();

            })
                .fail(function (response) {                    
                    $('.instagram-section').hide();
                });
            /* Instagram JS end */
        }
        //For Home Page 
        if ($(".default-page .custom-top-products").length > 0) {
            // For Most popular products
            if ($(".custom-top-products").length > 0) {

                var product_ids = [];
                product_ids.push(context.set_one_first);
                product_ids.push(context.set_one_second);
                product_ids.push(context.set_one_third);
                product_ids.push(context.set_two_first);
                product_ids.push(context.set_two_second);
                product_ids.push(context.set_two_third);
                product_ids.push(context.set_three_first);
                product_ids.push(context.set_three_second);
                product_ids.push(context.set_three_third);

                var full_result = graphqlquery(product_ids, context.bearerToken, context.currency_code);

                var ids = [];
                var prod_sec = ['first-sec', 'second-sec', 'third-sec'];
                ids[0] = product_ids.slice(0, 3);
                ids[1] = product_ids.slice(3, 6);
                ids[2] = product_ids.slice(6, 9);

                for (var x = 0; x < 3; x++) {
                    var product_cards = '';
                    for (var i = 0; i < 3; i++) {
                        if (ids[x][i].length > 0) {
                            for (let index = 0; index < full_result.data.site.products.edges.length; index++) {
                                var product = full_result.data.site.products.edges[index].node;
                                if (ids[x][i] == product.entityId) {
                                    var currency_symbol = full_result.data.site.currency.display.symbol;
                                    var productMrp = '';
                                    var productWas = '';
                                    var cart_url = '';
                                    if (product.prices.retailPrice != null)
                                        productMrp = '<div class="price-section price-section--withoutTax rrp-price--withoutTax"><span>MSRP:</span><span data-product-rrp-price-without-tax="" class="price price--rrp">' + currency_symbol + product.prices.retailPrice.value.toLocaleString() + '</span></div>';
                                    if (product.prices.basePrice != null && product.prices.price.value !== product.prices.basePrice.value)
                                        productWas = '<div class="price-section price-section--withoutTax non-sale-price--withoutTax"><span>Was:</span><span data-product-non-sale-price-without-tax="" class="price price--non-sale">' + currency_symbol + product.prices.basePrice.value.toLocaleString() + '</span></div>';
                                    if (product.inventory.isInStock) {
                                        if (product.availabilityV2.status == "Preorder") {
                                            cart_url = '<div class="card-btn"><a href="' + product.addToCartUrl + '" class="button button--primary" tabindex="0"><svg><use xlink:href="#icon-pre-order" /></svg><span>Pre-Order Now</span></a></div>';
                                        }
                                        else {
                                            if (product.productOptions.edges.length > 0)
                                                cart_url = '<div class="card-btn"><a href="' + product.addToCartUrl + '" class="button button--primary" tabindex="0"><svg><use xlink:href="#icon-choose-options" /></svg><span>Choose Options</span></a></div>';
                                            else
                                                cart_url = '<div class="card-btn"><a href="' + product.addToCartUrl + '" class="button button--primary" tabindex="0"><svg><use xlink:href="#icon-card-add-to-cart"></use></svg><span>Add to Cart</span></a></div>';
                                        }

                                    } else
                                        cart_url = '<div class="card-btn"><a href="' + product.path + '" class="button button--primary" tabindex="0"><svg><use xlink:href="#icon-out-of-stock"></use></svg><span>Out of stock</span></a></div>';
                                    product_cards += '<div><article class="card" data-test="" data-product-id="' + product.entityId + '"><figure class="card-figure"><a href="' + product.path + '" class="card-figure__link" tabindex="0">card-figure__link <div class="card-img-container"><img class="swatch-card-image card-image lazyautosizes ls-is-cached lazyloaded" data-sizes="auto" alt="card swatch" src="' + product.defaultImage.url640wide + '" data-src="' + product.defaultImage.url640wide + '" id="lazyload-mode-lqip" sizes="353px"> </div></a></figure> <div class="card-body card-swatch-body"><h3 class="card-title"><a href="' + product.path + '" tabindex="0">' + product.name + '</a></h3><div class="card-text" data-test-info-type="price"><div class="price-section price-section--withoutTax"><span class="price-label" style="display: none;"></span><span class="price-now-label">Now:</span><span data-product-price-without-tax="" class="price price--withoutTax">' + currency_symbol + product.prices.price.value.toLocaleString() + '</span></div>' + productMrp + ' ' + productWas + '</div><div class="card-text card-text--colorswatches loaded" data-product-card-options-content=""></div>' + cart_url + '</div></article></div>';
                                }

                            }
                        }
                    }

                    var product_html = '<div class="products-item owl-carousel owl-theme" data-sec="' + prod_sec[x] + '" id="' + prod_sec[x] + x + '">' + product_cards + '</div>';

                    $('.product-section.' + prod_sec[x]).html(product_html);
                    $('.product-section.' + prod_sec[x] + ' div.products-item.owl-carousel').owlCarousel({
                        loop: true,
                        dots: true,
                        margin: 10,
                        nav: false,
                        items: 1
                    });
                }
                var owl = $('.custom-top-products.owl-carousel');
                owl.trigger('refresh.owl.carousel');
                $('.top-products-section .loading #most-popular-loader').hide();
                setTimeout(function () {
                    for (var x = 0; x < 3; x++) {
                        $('.product-section.' + prod_sec[x] + ' div.products-item').find(".owl-dots button:nth-child(0)").trigger('click');
                    }
                    $('.top-products-section .custom-top-products').show();                    
                    $('.owl-dots .owl-dot').find('span').text('TEXT');
                }, 2000);
            }
        }

        // Most populer section home page DOT CLICK

        $(".dot-one").click(function () {
            var section = $(this).attr('data-sec');
            var index = $(this).index();
            $('.product-section.' + section + ' div.products-item .owl-dots button:nth-child(' + index + ')').click();
            $(this).addClass('active');
            $(this).siblings('.dot-two').removeClass('active');
            $(this).siblings('.dot-three').removeClass('active');
        });
        $(".dot-two").click(function () {
            var section = $(this).attr('data-sec');
            var index = $(this).index();
            $('.product-section.' + section + ' div.products-item .owl-dots button:nth-child(' + index + ')').click();
            $(this).addClass('active');
            $(this).siblings('.dot-one').removeClass('active');
            $(this).siblings('.dot-three').removeClass('active');
        });
        $(".dot-three").click(function () {
            var section = $(this).attr('data-sec');
            var index = $(this).index();
            $('.product-section.' + section + ' div.products-item .owl-dots button:nth-child(' + index + ')').click();
            $(this).addClass('active');
            $(this).siblings('.dot-two').removeClass('active');
            $(this).siblings('.dot-one').removeClass('active');
        });

        setTimeout(function () {
            $(".owl-item").not('.cloned').find(".product-section").each(function () {
                if ($(this).find(".owl-dots button").hasClass('active')) {
                    var data_sec = $(this).find('.products-item').attr('data-sec');
                    $(".owl-item").not('.cloned').find('.image-details.' + data_sec + ' span.dot.active').removeClass('active');
                    var index = $(this).index();
                    index = index - 1;
                    $(".owl-item").not('.cloned').find('.image-details.' + data_sec + ' span.dot:eq(' + index + ')').trigger('click');
                }
            });
        }, 1000);

        $(".owl-item").not('.cloned').find(".product-section .owl-dots button.owl-dot").click(function () {
            var data_sec = $(this).parents('.product-section').find('.products-item').attr('data-sec');
            $(".owl-item").not('.cloned').find('.image-details.' + data_sec + ' span.dot.active').removeClass('active');
            var index = $(this).index();
            $(".owl-item").not('.cloned').find('.image-details.' + data_sec + ' span.dot:eq(' + index + ')').addClass('active');
        });
        $('.slider-for').not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: false,
            fade: true,
            draggable: true,
            adaptiveHeight: true,
            asNavFor: '.slider-nav'
        });

        $('.slider-nav').not('.slick-initialized').slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            arrows: true,
            dots: false,
            focusOnSelect: true,
            infinite: false,
            responsive: [{
                breakpoint: 1699,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1299,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 5,
                    verticalSwiping: false,
                    vertical: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    verticalSwiping: false,
                    vertical: false
                }
            },
            {
                breakpoint: 569,
                settings: {
                    slidesToShow: 3,
                    vertical: false,
                    verticalSwiping: false
                }
            }
            ]
        });


        if ($('.productView-main-thumbnail').length > 0) {
            var sliderInterval = setInterval(function () {
                $('.slider-for .slick-list.draggable .productView-main-thumbnail.slick-current.slick-active').css('width', 'auto');
                var img_height = $('.slider-for .slick-list.draggable .productView-main-thumbnail.slick-current.slick-active').css('height');
                $('.slider-for .slick-list.draggable').css('height', img_height);
                $('.slider-for .slick-list.draggable .slick-track').css('width', 'auto');
            }, 500);

            setTimeout(function () {
                clearInterval(sliderInterval);
            }, 8000);
        }

        $('.slider-nav .slick-slide').on('click', function (event) {
            $('.slider-for').slick('slickGoTo', $(this).data('slickIndex'));
            $('.slider-nav .slick-slide').removeClass('slick-current');
            $(this).addClass('slick-current');
        });

        $('.slider-for').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $('.slider-nav .slick-slide').removeClass('slick-current');
            $('.slider-nav .slick-slide[data-slick-index="' + nextSlide + '"]').addClass('slick-current');
        });

        $(function () {
            $(".productView-description ul.tabs li:first").addClass("is-active");
            $(".productView-description .tabs-contents .tab-content:first").addClass("is-active");
        });

        $(document).on('click', '.share-icons .share-link', function (e) {            
            $(".share-icons .share-data").stop().slideToggle(1000);
        });

        $('.productView-description .productView-title').click(function () {
            $('.productView-description .productView-title').not(this).removeClass('is-active');
            $('.productView-description .productView-title').not(this).next().slideUp(500);
            $(this).toggleClass('is-active');
            $(this).next().slideToggle(500);
        });

        $('#productReview_link').click(function () {
            $('.productView-reviewTabLink').addClass('is-active');
            $('#tab-reviews').addClass('is-active');
            $('.productView-review-title').trigger('click');
            $('.productView-review-title + .productView-description').slideDown('350');
            $('html, body').animate({
                scrollTop: ($("#product-reviews").offset().top - 100)
            }, 1000);
        });

    
        // Added for Fancy box to work on Product page START
        var $fancyboxlinks = $('.hiddengallery');
        
        $(document).on('click', '.fancybox-gallery', function() {
              Fancybox.bind('[data-fancybox="gallery1"]', {
                Thumbs : {
                  showOnStart: false
                }
              }); 
        });

        $('.productView-thumbnail').on('click', function () {
            $('.fancybox-hidden-img').removeClass('currentGalleryImage');
            $('.fancybox-hidden-img').eq($(this).attr('data-slick-index')).addClass('currentGalleryImage');
        });

        $('.productView-thumbnail').on('click', function () {
            $('.fancybox-hidden-img').removeClass('currentGalleryImage');
            $('.fancybox-hidden-img').eq($(this).attr('data-slick-index')).addClass('currentGalleryImage');
        });

        $('.slider-for').on('afterChange', function (event, slick, currentSlide, nextSlide) {
            $('.fancybox-hidden-img').removeClass('currentGalleryImage');
            $('.fancybox-hidden-img').eq(currentSlide).addClass('currentGalleryImage');
        });

        // Added for Fancy box to work on Product page END

        // category sub category menu open and hide START
        $(document).on('click', '.accordion-block .navPages-list .action-icon', function () {
            var target = $(this);
            var closest_ul = target.closest('ul');
            var parallel_active_links = closest_ul.find(".is-open");
            var closest_li = target.closest('li');
            var link_status = closest_li.children('.action-icon').hasClass('is-open');
            var count = 0;

            closest_ul.find('div').slideUp(function () {
                if (++count == closest_ul.find('div').length)
                    parallel_active_links.removeClass('is-open');
            });
            if (!link_status) {
                closest_li.children('div').slideDown('slow');
                target.addClass('is-open');
            }
        });
        // category sub category menu open and hide END

        $('.form-field').on('click', '.form-option-wrapper', function () {
            $('.slider-for').slick('slickGoTo', 0);
        });

    });

    // search modal close start
    $(document).on("click touchstart", ".quickSearchResults .modal-close", function () {
        $(this).parents(".quickSearchResults").siblings(".form").find(".form-input").val("");
        $(this).parents(".quickSearchResults").html("");
    });

});

function graphqlquery(prod_ids, bearerToken, currencyCode) {
    var query =
        `query paginateProducts{
            site {
              products (entityIds:[` + prod_ids + `]) {
               
                edges {
                  node {
                    entityId
                    name
                path
                addToCartUrl
                availabilityV2{
                    status
                    ... on ProductPreOrder{
                      status
                      message
                      willBeReleasedAt
                      {
                        utc
                      }
                    }
                  }
                inventory{
                isInStock
                }
                productOptions{
                  edges{
                    node{
                    displayName
                    isRequired
                    isVariantOption
                    }
                  }
                }
                    defaultImage {
                  ...ImageFields
                }
                images {
                  edges {
                    node {
                altText
                      ...ImageFields
                    }
                  }
                }
                reviewSummary {
                  summationOfRatings
                  numberOfReviews
                }
                prices {
                  price {
                    ...MoneyFields
                  }
              basePrice{
                    ...MoneyFields
                  }
              mapPrice{
                    ...MoneyFields
                  }
                  salePrice {
                    ...MoneyFields
                  }
                  retailPrice {
                    ...MoneyFields
                  }
                  saved {
                    ...MoneyFields
                  }
                }
                  }
                }
              }
              currency(currencyCode:`+ currencyCode + `)
                {
                code
                isActive
                display
                {
                    symbol
                    decimalToken
                    decimalPlaces
                    thousandsToken
                }
                }
            }
          }
          fragment ImageFields on Image {
            url320wide: url(width: 320)
            url640wide: url(width: 640)
            url960wide: url(width: 960)
            url1280wide: url(width: 1280)
          }

          fragment MoneyFields on Money {
            value
            currencyCode
          }`



    var graphql_query_result = $.ajax({

        url: "/graphql",
        contentType: "application/json",
        type: 'POST',
        async: false,
        headers: {
            'Authorization': 'Bearer ' + bearerToken
        },
        data: JSON.stringify({ query: query }),
        success: function (productSlickData) {

        }
    });
    return graphql_query_result.responseJSON;

}

/* eslint-enable */
